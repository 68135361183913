// router/index.js
import { createRouter, createWebHistory } from 'vue-router';

// 페이지 컴포넌트 임포트
import MainView from '@/components/MainView.vue';
import CvCompView from '@/components/CvCompView.vue';
import ArtStateView from '@/components/ArtStateView.vue';
import ImageCompView from '@/components/ImageCompView.vue';
import AproComp from '@/components/project/AproComp.vue';
import NetworkGalleryView from '@/components/work/NetworkGalleryView.vue';
import ContentDetailView from '@/components/work/ContentDetailView.vue';

// 기본 타이틀 설정 (필요 시 변경)
const DEFAULT_TITLE = 'TaejunYun 윤태준 - Gallery';

const routes = [
  {
    path: '/',
    name: 'MainView',
    component: MainView,
    meta: { title: 'TaejunYun 윤태준', description: '메인 페이지 설명입니다.' }
  },
  {
    path: '/about',
    name: 'CvCompView',
    component: CvCompView,
    meta: { title: 'CV', description: '소개 페이지 설명입니다.' }
  },
  {
    path: '/text',
    name: 'ArtStateView',
    component: ArtStateView,
    meta: { title: 'Text', description: '텍스트 페이지 설명입니다.' }
  },
  {
    path: '/work/:workId',
    name: 'ImageCompView',
    component: ImageCompView,
    props: true,
    meta: { title: 'Works', description: '작업 페이지 설명입니다.' }
  },
  {
    path: '/project',
    name: 'AproComp',
    component: AproComp,
    meta: { title: 'Intallation&etc', description: '프로젝트 페이지 설명입니다.' }
  },
  {
    path: '/research/:workId',
    name: 'NetworkGalleryView',
    component: NetworkGalleryView,
    props: true,
    meta: { title: 'Networks Research', description: '연구 페이지 설명입니다.' }
  },
  {
    path: '/research/:workId/content/:contentId',
    name: 'ContentDetailView',
    component: ContentDetailView,
    props: true,
    meta: { title: 'Research', description: '상세 페이지 설명입니다.' }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 라우트 전환 후 메타 정보 및 Analytics 페이지뷰 처리
router.afterEach((to) => {
  const pageTitle = to.meta?.title || DEFAULT_TITLE;
  document.title = pageTitle;

  // Google Analytics 페이지뷰 이벤트 전송
  if (typeof window.gtag === 'function') {
    window.gtag('event', 'page_view', {
      page_path: to.fullPath,
      page_title: pageTitle,
    });
  } else {
    console.warn('Google Analytics gtag is not initialized');
  }

  // 필요하면 description 메타 태그도 동적으로 변경 가능
  const descriptionMeta = document.querySelector('meta[name="description"]');
  if (descriptionMeta) {
    descriptionMeta.setAttribute('content', to.meta?.description || '');
  }
});

export default router;